import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./DashboardDownloadLinks.css";

function DashboardDownloadLinks() {
  return (
    <>
      <div className="dashboard-download-links">
        <a
          href="https://apps.apple.com/us/app/id1456312892"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../images/app-store-base.png"
            width={200}
            alt="Download on the Apple App Store"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.roundedlearning.problemscapeValueOfXperts"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            width={200}
            src="../images/google-play-badge-base.png"
            alt="Download on Google Play Store"
          />
        </a>
        {/* <a
          href="https://www.microsoft.com/store/productId/9NB5NZVQ07V4?ocid=pdpshare"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            width={200}
            src="../images/window-app.png"
            alt="Download on Windows Store"
          />
        </a> */}
      </div>
    </>
  );
}

export default DashboardDownloadLinks;
